var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return Object.keys(_vm.profileData).length ? _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('profile-header', {
    attrs: {
      "header-data": _vm.profileData.header
    }
  }), _c('section', {
    attrs: {
      "id": "profile-info"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "cols": "12",
      "order": "2",
      "order-lg": "1"
    }
  }, [_c('profile-about', {
    attrs: {
      "about-data": _vm.profileData.userAbout
    }
  }), _c('profile-suggested-pages', {
    attrs: {
      "pages-data": _vm.profileData.suggestedPages
    }
  }), _c('profile-twitter-feed', {
    attrs: {
      "twitter-feed": _vm.profileData.twitterFeeds
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('profile-post', {
    attrs: {
      "posts": _vm.profileData.post
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "cols": "12",
      "order": "3"
    }
  }, [_c('profile-latest-photos', {
    attrs: {
      "latest-images": _vm.profileData.latestPhotos
    }
  }), _c('profile-suggestion', {
    attrs: {
      "suggestions": _vm.profileData.suggestions
    }
  }), _c('profile-polls', {
    attrs: {
      "polls-data": _vm.profileData.polls
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "order": "4"
    }
  }, [_c('profile-bottom')], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }