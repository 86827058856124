var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', _vm._l(_vm.aboutData, function (data, key, index) {
    return _c('div', {
      key: data.title,
      class: index ? 'mt-2' : ''
    }, [_c('h5', {
      staticClass: "text-capitalize mb-75"
    }, [_vm._v(" " + _vm._s(key) + " ")]), _c('b-card-text', [_vm._v(" " + _vm._s(data) + " ")])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }